import styled from '@emotion/styled';

import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const WritingSection = styled.section<{ theme: CustomTheme }>`
  ${Shared.sectionStyle}

  font-family: ${({ theme }) => theme.fonts.serif};
  font-style: italic;
  font-size: 80px;
`;