import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import * as Styled from './style';

const SectionNav = () => (
  <Styled.SectionNav>
    <AnchorLink to="/#writing" title="Writing">
      Writing
    </AnchorLink>
    <AnchorLink to="/#work" title="Work">
      Work
    </AnchorLink>
    <AnchorLink to="/#contact" title="Contact">
      Contact
    </AnchorLink>
  </Styled.SectionNav>
);

export default SectionNav;

