import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const Ticker = styled.div<{ theme: CustomTheme }>`
  ${Shared.gridBorderStyle()}
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;

  position: sticky; 
  top: ${SPACER.x4large}; 
  height: ${SPACER.xlarge};

  display: flex; 
  align-items: center;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`;

export const Marquee = styled.div<{ isPaused: boolean; }>`
  animation: marquee 128s linear infinite; 
  animation-play-state: ${({ isPaused }) => isPaused ? 'paused' : 'running'};

  @keyframes marquee {
    0% {
        transform: translate3d(16px, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
  }

  > ul {
    list-style: inside "✸"; 
    display: flex; 

    li::before, li::after {
      content: " ";
      white-space: pre;
    }

    a {
      text-decoration: underline dashed;
    }
  }
`;