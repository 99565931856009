import { graphql, useStaticQuery } from 'gatsby';

const useTickerContentQuery = () => {
  const { file } = useStaticQuery(graphql`
    query TICKER_CONTENT_QUERY {
      file(sourceInstanceName: { eq: "ticker" }) {
        childMarkdownRemark {
          html
        } 
      }
    }
  `);

  return { file }; 
};

export default useTickerContentQuery;