import React from 'react';

import * as Styled from './style';

const RisingTide = () => (
  <Styled.RisingTide />
);

export default {
  Component: RisingTide, 
  title: 'Rising Tide Placeholder',
  createdAt: '2020-12-20',
  technique: 'CSS, linear gradient, keyframe animation.',
};