import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const SectionNav = styled.div<{ theme: CustomTheme }>`
  ${Shared.gridBorderStyle()}
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;
  
  position: sticky; 
  top: calc(${SPACER.x4large} + ${SPACER.xlarge});

  height: ${SPACER.x4large};
  padding: 0 ${SPACER.base};
  display: flex; 
  justify-content: space-between;
  align-items: center;

  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 20px; 
  font-style: italic;
`;