import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const Exhibition = styled.figure`
  ${Shared.gridBorderStyle()}
  position: relative;
  z-index: -1;

  height: calc(100vh - ${SPACER.x4large} - ${SPACER.xlarge});
`;

export const Label = styled.figcaption<{ theme: CustomTheme }>`
  max-width: 100%;
  position: absolute;
  bottom: 0; 
  right: 0; 
  padding: ${SPACER.xsmall} ${SPACER.small};
  z-index: 2;
  
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;

  border-top: 1px solid;
  border-left: 1px solid;
  
  font-size: 14px;
  line-height: 1.25;
  white-space: pre-wrap;
`;

export const Title = styled.span`
  font-weight: bold;
  font-style: italic;
`;

export const Date = styled.span`
  font-style: italic;
`;