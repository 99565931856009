import { css } from '@emotion/core';

import { SPACER } from 'consts';

export const gridBorderStyle = ({ shouldIncludeTop=false } = {}) => css`
  width: 100%;
  border: 1px solid;

  ${!shouldIncludeTop && 'border-top: 0;'}
`;

export const sectionStyle = css`
  border: 1px dashed;
  border-top: 0;
  height: calc(100vh - 144px);

  display: flex; 
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  text-align: center; 

  padding: ${SPACER.base};
`;