import React from 'react';

import { Link } from 'gatsby';

import * as Styled from './style';

const Work = () => (
  <Styled.WorkSection id="work">
    Coming soon ...
    <Styled.Meanwhile>
      Until then, see some projects{' '}
      <Link to="/projects/">here</Link>
    </Styled.Meanwhile>
  </Styled.WorkSection>
);

export default Work;