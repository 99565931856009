import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const WorkSection = styled.section<{ theme: CustomTheme }>`
  ${Shared.sectionStyle}

  font-family: ${({ theme }) => theme.fonts.serif};
  font-style: italic;
  font-size: 48px;
`;

export const Meanwhile = styled.div<{ theme: CustomTheme }>`
  margin-top: ${SPACER.large};
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 24px;
  line-height: 1.125;

  a {
    font-weight: bold;
  }
`;