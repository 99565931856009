import styled from '@emotion/styled';

import { SPACER } from 'consts';

export const RisingTide = styled.div`
  position: absolute;
  top: ${SPACER.x4large};
  left: 0; 
  right: 0; 
  bottom: 0; 

  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 40%, rgba(0,212,255,0.8) 100%);
  background-size: 200% 200%;

  animation: tide 32s ease-in-out infinite alternate;

  @keyframes tide { 
    0% {
      background-position: 100% 0%
    }
    50% {
      background-position: 0% 0%
    }
    100% {
      background-position: 0% 100%
    }
}
`;