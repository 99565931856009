import React from 'react';

import * as Styled from './style';

// TODO: Randomize contact emojis.
const Contact = () => (
  <Styled.ContactSection id="contact">
    <Styled.Heading>
      <span role="img" aria-label="eye">👁</span>
      <span role="img" aria-label="red-post-box">📮</span>
      <span role="img" aria-label="eye">👁</span>
    </Styled.Heading>
    <Styled.ContactMethods>
      <div>hi@<span />yihwan.kim</div>
      <div>@yihwan everywhere else</div>
    </Styled.ContactMethods>
  </Styled.ContactSection>
);

export default Contact;