import React, { useState } from 'react';

import useTickerContentQuery from 'hooks/useTickerContentQuery';

import * as Styled from './style';

const Ticker = () => {
  const [isPaused, setIsPaused] = useState(true);
  const { file: { childMarkdownRemark: { html } } } = useTickerContentQuery(); 

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).nodeName === 'A') {
      setIsPaused(true);

      return;
    }

    setIsPaused(prevState => !prevState);
  };

  return(
    <Styled.Ticker role="button" onClick={handleClick}>
      <Styled.Marquee 
        isPaused={isPaused} 
        dangerouslySetInnerHTML={{ __html: html }} 
      />
    </Styled.Ticker>
  );
};

export default Ticker;