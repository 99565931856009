import React from 'react';

import Layout from 'components/layout';
import Home from 'components/home';

const HomePage = ({ location }: { location: Location }) => (
  <Layout
    seo={{
      title: 'Hi',
    }}
    location={location}
  >
    <Home />
  </Layout>
);

export default HomePage;
