import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../style';

export const ContactSection = styled.section`
  ${Shared.sectionStyle}
  border-bottom: 1px solid;
`;

export const Heading = styled.div`
  font-size: 88px;
  font-family: emoji;
`;

export const ContactMethods = styled.div<{ theme: CustomTheme }>`
  margin-top: ${SPACER.base};
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 24px;

  > div {
    margin-top: ${SPACER.base};
  }
`;