import React from 'react';
import { Link } from 'gatsby';

import * as Styled from './style';

const Writing = () => (
  <Styled.WritingSection id="writing">
    <Link to="/blog/">Blog</Link>
  </Styled.WritingSection>
);

export default Writing;