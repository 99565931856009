import React from 'react';

import Exhibition from './components/exhibition';
import Ticker from './components/ticker';
import SectionNav from './components/sectionNav';
import Writing from './components/writing';
import Work from './components/work';
import Contact from './components/contact';

const Home = () => (
  <>
    <Exhibition />
    <Ticker />
    <SectionNav />
    <Writing />
    <Work />
    <Contact />
  </>
);

export default Home;