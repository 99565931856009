import React from 'react';

import { RisingTide } from 'components/exhibits';

import * as Styled from './style';

const Exhibition = () => (
  <Styled.Exhibition>
    <RisingTide.Component />
    <Styled.Label>
      <Styled.Title>{RisingTide.title}, </Styled.Title><Styled.Date>{getYearFromDate(RisingTide.createdAt)}</Styled.Date>.{' '}
      <span>{RisingTide.technique}</span>
    </Styled.Label>
  </Styled.Exhibition>
);

const getYearFromDate = (date: string) => (
  new Date(date).getFullYear()
);

export default Exhibition;